import React, { StrictMode } from "react";
import Footer from "../footer/Footer";
import "./Pages.css";
import wasserWell from "../../assets/water.png"
import clothes from "../../assets/cloth.png"
import food from "../../assets/food.png"
import money from "../../assets/money.png"
import education from "../../assets/training.png"
import job from "../../assets/job-fair_11666423.png"
import health from "../../assets/health.png"



import { Trans } from 'react-i18next'
const Home = () => {
  return (
    <StrictMode>
      <div className="home">
       <Trans i18nKey="Home-Content-2" components={{ p: <p/> , pre: <pre/>, h4: <h4/>, ul: <ul/>, li: <li/>, strong: <strong/>, h5: <h5/>, text: <text/>}} />
      <table>
      <tr>
        <td style={{padding:40, textAlign:"center", verticalAlign:"middle"}}><img width={150} height={150} src={wasserWell} alt="ESNAD e.V" /></td>
        <td style={{padding:40, textAlign:"center", verticalAlign:"middle"}}><img width={150} height={150} src={food} alt="ESNAD e.V" /></td>
        <td style={{padding:40, textAlign:"center", verticalAlign:"middle"}}><img width={150} height={150} src={clothes} alt="ESNAD e.V" /></td>
      </tr>
      <tr>
        <td style={{padding:40, textAlign:"center", verticalAlign:"middle"}}><Trans i18nKey="Home-Wasser" components={{ p: <p/> , strong: <strong/>, h5: <h5/>, text: <text/>}} /></td>
        <td style={{padding:40, textAlign:"center", verticalAlign:"middle"}}><Trans i18nKey="Home-Food" components={{ p: <p/> , strong: <strong/>, h5: <h5/>, text: <text/>}} /></td>
        <td style={{padding:40, textAlign:"center", verticalAlign:"middle"}}><Trans i18nKey="Home-Cloth" components={{ p: <p/> , strong: <strong/>, h5: <h5/>, text: <text/>}} /></td>
      </tr>
      <tr>
        <td style={{padding:40, textAlign:"center", verticalAlign:"middle"}}><img width={150} height={150} src={money} alt="ESNAD e.V" /></td>
        <td style={{padding:40, textAlign:"center", verticalAlign:"middle"}}><img width={150} height={150} src={education} alt="ESNAD e.V" /></td>
        <td style={{padding:40, textAlign:"center", verticalAlign:"middle"}}><img width={150} height={150} src={health} alt="ESNAD e.V" /></td>
      </tr>
      <tr>
        <td style={{padding:40, textAlign:"center", verticalAlign:"middle"}}><Trans i18nKey="Home-Help" components={{ p: <p/> , strong: <strong/>, h5: <h5/>, text: <text/>}} /></td>
        <td style={{padding:40, textAlign:"center", verticalAlign:"middle"}}><Trans i18nKey="Home-Education" components={{ p: <p/> , strong: <strong/>, h5: <h5/>, text: <text/>}} /></td>
        <td style={{padding:40, textAlign:"center", verticalAlign:"middle"}}><Trans i18nKey="Home-Health" components={{ p: <p/> , strong: <strong/>, h5: <h5/>, text: <text/>}} /></td>
      </tr>
      </table>

       <Trans i18nKey="Home-Content-3" components={{ p: <p/> , pre: <pre/>, h4: <h4/>, ul: <ul/>, li: <li/>, strong: <strong/>, h5: <h5/>, text: <text/>}} />
       <p>
       <Trans i18nKey="Home-Welcome" components={{ p: <p/> , pre: <pre/>, h4: <h4/>, ul: <ul/>, li: <li/>, strong: <strong/>, h5: <h5/>, text: <text/>}} /></p>
       {/*<Trans i18nKey="Home-Content-1" components={{ p: <p/> , pre: <pre/>, h4: <h4/>, ul: <ul/>, li: <li/>, strong: <strong/>, h5: <h5/>, text: <text/>}} />*/}
       
       </div>
      <div>
        <Footer />
      </div>
    </StrictMode>
  );
};

export default Home;
